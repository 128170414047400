.headerval-font-size{
    font-size: 25px;
}
.setmarginbottomzero{
    margin-bottom:0px;
    font-size: 23px;
}
.description-font-size{
    font-size: 15px;
    color: #007bff;
}
.headerval-font-sizew2{
    font-size: 15px;   
}
.description-font-sizew2{
    font-size: 10px;
    color: #007bff;
}
.remove-padding-right-for-card{
    padding-right : 0px;
}
.set-desc-fontsize-color{
    color:grey;
    font-size:24px;
}
.remove-card-body-over-spacing-left-right{
    padding-left:10px;
    padding-right:10px;
}
.remove-padding{
    margin-top: 2px;
    margin-bottom:2px;
    border-top:1px solid white;
}